import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import { component as Footer } from "@layout/footer"
import { component as Header } from "@layout/header"

import Layout from "@layout/no-header-footer"
import Grid from "@layout/grid"
import Card from "@components/card"
import Carousel from "@components/carousel"
import Image from "@components/image"
import Breadcrumbs from "@components/breadcrumbs"
import Container from "@layout/container"

class ProductCategoryPage extends React.Component {
  // data alias
  page = this.props.data.datoCmsProductCategoryTruckVan
  section = this.props.pageContext.section

  getProductsSection() {
    return (
      <Grid items={`${this.props.data.products.nodes.length}`} hasChildren gutter="15" layout="3">
        {this.props.data.products.nodes.map((product, index) => {
          const { title, section, category, permalink, images } = product
          const categoryLink = `/${section.permalink}/${category.permalink}`
          const cardData = {
            body: `<a href="${categoryLink}">${category.title}</a>`,
            heading: title,
            image: {
              childImageSharp: {
                gatsbyImageData: images ? images[0].gatsbyImageData : undefined
              }
            },
            theme: "media",
            url: `${categoryLink}/${permalink}`
          }

          return <Card key={index} {...cardData} />
        })}
      </Grid>
    )
  }

  getBrandsCarousel() {
    const brands = this.page.brandsCarousel

    if (brands) {
      return (
        <div>
          <Carousel theme={brands.theme} title="Brands" leftAlignTitle={true}>
            {brands.slides.map((slide, index) => {
              const image = {
                childImageSharp: {
                  gatsbyImageData: {
                    ...slide.image.gatsbyImageData
                  }
                }
              }
              return <Image key={index} src={image} link={slide.linkTo} />
            })}
          </Carousel>
        </div>
      )
    }

    return null
  }

  render() {
    const bodyContent =
      this.page.bodyNode && this.page.bodyNode.childMarkdownRemark
        ? this.page.bodyNode.childMarkdownRemark.html
        : this.page.body

    return (
      <>
        <Helmet>
          <html lang="en" />
        </Helmet>

        <Header {...this.props.data.menu} />

        <Layout seoMetaTags={this.page.seoMetaTags} useTriangleBackground={true}>
          <div style={{ marginBottom: "60px", marginTop: "75px" }}>
            <Breadcrumbs
              links={[
                {
                  label: this.section.title,
                  url: `/${this.section.permalink}`
                }
              ]}
            />
            {this.page.title && <h1>{this.page.title}</h1>}
            {this.page.body && (
              <div
                style={{ marginBottom: "75px", maxWidth: "690px" }}
                dangerouslySetInnerHTML={{
                  __html: bodyContent
                }}
              />
            )}
            {this.page.products && <h2 style={{ marginBottom: "40px" }}>Products</h2>}
            {this.getProductsSection()}
          </div>
        </Layout>

        <div style={{ marginBottom: "-12px" }}>
          <Container hasChildren theme="background-is-gray" greenBorder={true}>
            {this.getBrandsCarousel()}
          </Container>
        </div>

        <Footer {...this.props.data.footer} />
      </>
    )
  }
}

export default ProductCategoryPage

export const query = graphql`
  query ProductCategoryTruckVanQuery($permalink: String!) {
    ...MegaMenu
    ...Footer

    products: allDatoCmsProductTruckVan(
      filter: { productCategory: { permalink: { eq: $permalink } } }
    ) {
      nodes {
        title
        permalink
        category: productCategory {
          title
          permalink
        }
        section: productSection {
          permalink
        }
        images {
          gatsbyImageData(aspectRatio: 1.5)
        }
      }
    }

    datoCmsProductCategoryTruckVan(permalink: { eq: $permalink }) {
      permalink
      title
      body
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
      seoMetaTags {
        tags
      }
      brandsCarousel {
        theme
        title
        slides {
          ... on DatoCmsImageOld {
            linkTo
            image {
              gatsbyImageData
            }
          }
          ... on DatoCmsContent {
            body
          }
        }
      }
    }
  }
`
